<template>
  <article class="formulas">
    <section>
      <template>
        <div>
          <template>
            <div>
              <v-card class="pa-4" outlined>
                <h5>Bandeja de fórmulas > {{ addTitle }}</h5><br>
                <template>
                  <v-row>
                    <v-col cols="12" md="4" class="d-flex align-center">
                      <h6 class="text-lg font-semibold text-gray-700 mr-2">Scanner:</h6>
                      <v-select v-model="selectedScanner" :items="listaScanner" item-text="hostname"
                        item-value="hostname" label="Seleccione un scanner" class="w-100" outlined dense hide-details
                        :menu-props="{ offsetY: true }" @change="guardarSeleccion"
                        no-data-text="No hay scanners registrados"></v-select>
                    </v-col>
                  </v-row>
                </template>

                <v-row>
                  <!-- Columna derecha: Subir PDF o arrastrar -->
                  <v-col cols="12" md="6">
                    <div class="drop-zone" @dragover.prevent @drop.prevent="handleDrop">
                      <v-icon large color="grey">mdi-file-upload</v-icon>
                      <p>Arrastra aquí tu archivo PDF o</p>
                      <v-btn color="primary" @click="selectPdfFile">
                        <v-icon color="white" class="mr-1">{{ icons.fileUpload }}</v-icon>
                        Seleccionar Fórmula
                      </v-btn>
                    </div>
                    <input type="file" ref="pdfInput" @change="onFileChange" accept="application/pdf" hidden />
                  </v-col>

                  <v-col cols="12" md="6" class="d-flex align-center justify-center">
                    <v-btn color="primary" large @click="scan" class="scan-btn"
                      :disabled="!selectedScanner || !listaScanner.some(scanner => scanner.hostname === selectedScanner)">
                      <v-icon color="white" class="mr-1" large>{{ icons.scanner }}</v-icon>
                      Escanear Fórmula
                    </v-btn>
                  </v-col>


                </v-row>
              </v-card>

              <!-- Modal para mostrar PDF -->
              <v-dialog v-model="showPdfDialog" max-width="900px">
                <v-card>
                  <v-toolbar color="primary" dark>
                    <v-toolbar-title>Vista previa de la fórmula</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="showPdfDialog = false">
                      <v-icon color="white" class="mr-1">{{ icons.close }}</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-card-text>
                    <iframe v-if="pdfBase64" :src="pdfBase64" width="100%" height="800px"></iframe>
                  </v-card-text>
                  <!-- Botón flotante -->
                  <v-btn color="primary" dark bottom right class="ma-4" @click="guardarFormula"
                    style="position: absolute; top: 65px; right: 20px;">
                    <v-icon color="white" class="mr-1">{{ icons.save }}</v-icon>
                    Guardar fórmula
                  </v-btn>
                </v-card>
              </v-dialog>
            </div>
          </template>


          <!--<v-btn color="primary" class="ma-2" @click="scanMultiple">
                <v-icon left>mdi-scanner</v-icon> Escaneo múltiple
              </v-btn> -->

          <!--<v-btn color="secondary" class="ma-2" @click="openCustomScanModal">
                <v-icon left>mdi-tune</v-icon> Escaneo personalizado
              </v-btn> -->

          <!-- Modal escaneo personalizado -->
          <v-dialog v-model="showCustomScanModal" max-width="500px">
            <v-card>
              <v-card-title class="headline">Escaneo personalizado</v-card-title>
              <v-card-text>
                <v-text-field v-model="customScanSettings.pages" label="Cantidad de hojas" type="number" min="1" />
                <v-select v-model="customScanSettings.dpi" :items="[100, 200, 300, 600]" label="DPI" />
                <v-select v-model="customScanSettings.colorMode" :items="['Color', 'Grises', 'Blanco y negro']"
                  label="Modo de color" />
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="showCustomScanModal = false">Cancelar</v-btn>
                <v-btn color="primary" @click="startCustomScan">Iniciar escaneo</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </template>
      <v-snackbar v-model="snackbar" :color="snackbarColor" bottom timeout="6000" multi-line>
        {{ snackbarMessage }}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>
      <br>
      <template>
        <div class="tables-container">
          <!-- Tabla de Fórmulas (Izquierda) -->
          <v-card class="table-card-formulas">
            <v-card-title>Crear expediente manualmente</v-card-title>
            <v-data-table :headers="formulaHeaders" :items="formulas" class="elevation-1" dense hide-default-footer
              fixed-header height="600" :items-per-page="-1">
              <!-- Formatear fecha a solo hora en formato hh:mm A -->
              <template v-slot:item.createDate="{ item }">
                <div>
                  <h5>{{ formatTime(item.createDate) }}</h5>
                </div>
              </template>

              <template #item.nombre_paciente="{ item }">
                <div>
                  {{ getNombrePaciente(item.dataJson) }}
                </div>
              </template>

              <!-- Botón PDF deshabilitado si estadoFormulaEscaneada es "PROCESSING" -->
              <template v-slot:item.actions="{ item }">
                <v-icon @click="redirectToDigitalizador(item)" class="cursor-pointer pdf me-2"
                  :disabled="item.estadoFormulaEscaneada === 'PROCESSING'"
                  :class="{ 'disabled-icon': item.estadoFormulaEscaneada === 'PROCESSING' }">
                  picture_as_pdf
                </v-icon>
              </template>
            </v-data-table>
          </v-card>

          <!-- Tabla de Expedientes (Derecha) -->
          <v-card class="table-card-expedientes">
            <v-card-title>Expedientes creados hoy</v-card-title>
            <v-data-table :headers="expedienteHeaders" :items="expedientes" class="elevation-1" dense
              hide-default-footer fixed-header height="600" :items-per-page="-1">
              <template v-slot:item.createDate="{ item }">
                {{ new Date(item.createDate).toLocaleTimeString('en-US', {
                  hour: '2-digit', minute: '2-digit', hour12:
                    true
                }) }}
              </template>

              <!-- Estado siempre verde -->
              <template v-slot:item.estado>
                <v-chip color="green" dark>
                  Expediente Creado
                </v-chip>
              </template>
            </v-data-table>
          </v-card>

        </div>
      </template>
    </section>
    <div class="loading" v-if="loading">
      <div class="loadingio-spinner-spinner-2by998twmg8">
        <div class="ldio-yzaezf3dcmj">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <h4>Cargando Bandeja...</h4>
    </div>
  </article>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import SockJS from "sockjs-client";
import Stomp from "stompjs";
import { mdiScanner, mdiFileUpload, mdiContentSave, mdiClose } from "@mdi/js"; // Importar íconos de MDI

const env = (process.env.VUE_APP_ENV || "local").toUpperCase();
const wsUrl = process.env[`VUE_APP_WS_${env}`] || "";

export default {
  name: 'Index',

  data: () => ({
    icons: {
      scanner: mdiScanner,
      fileUpload: mdiFileUpload,
      save: mdiContentSave,
      close: mdiClose
    },
    expedienteHeaders: [
      { text: 'ID', value: 'idExpediente' },
      { text: 'Hora', value: 'createDate' },
      { text: 'Documento', value: 'afiliado.numeroDocumento' },
      { text: 'Afiliado', value: 'afiliado.nombreCompleto' },
      { text: 'Creado por', value: 'createdby' },
      { text: 'Estado', value: 'estado' }
    ],
    formulaHeaders: [
      { text: 'Hora', value: 'createDate' },
      { text: 'Paciente', value: 'nombre_paciente' },
      { text: 'Estado', value: 'estadoFormulaEscaneada' },
      { text: 'Escaneado Por', value: 'createdby' },
      { text: 'PDF', value: 'actions', sortable: false },
    ],
    showPdfDialog: false,
    selectedScanner: null,
    pdfBase64: "",
    mdiScanner,
    mdiFileUpload,
    stompClient: null,
    tab: null,
    rolesKeycloak: null,
    codigosEmpresas: [],
    dataEmpresas: [],
    loading: true,
    formulas: [],
    usuario: "",
    bodega: "",
    afiliado: "",
    fecha: "",
    fecha2: "",
    isAdmin: false,
    bodegas: [],
    bodegaId: "",
    nombreBodega: "",
    addTitle: "",
    menu: false,
    menu2: false,
    timeoutId: null,
    expedientes: [],
    idEmpresa: "",
    snackbar: false,
    snackbarMessage: '',
    snackbarColor: 'success',
    intervalId: null,
    showCustomScanModal: false,
    listaScanner: [],
    userRole: "",
    env: "",
    customScanSettings: {
      cantidadHojas: 1,
      resolucion: 300
    },
  }),
  watch: {
    listaScanner(newList) {
      if (newList.length === 1) {
        this.selectedScanner = newList[0].hostname; 
        this.guardarSeleccion();
      }
    }
  },
  mounted: async function () {
     // Validar que process.env.VUE_APP_ENV esté definido

    this.usuario = this.auth.username;
    await this.empresasUsuario();
    await this.getBodega();
    this.getFormulas();
    await this.getScanner();
    this.cargarSeleccion();

    const vuexState = JSON.parse(localStorage.getItem('vuex'));
    if (vuexState.auth.roles.includes("Expediente_Regente")) {
      this.addTitle = "Regente";
    }
    if (vuexState.auth.roles.includes("Expediente_Administrador")) {
      this.isAdmin = true;
      this.addTitle = "Administrador";
    }
    if (vuexState.auth.roles.includes("Expediente_Digitalizador")) {
      this.addTitle = "Digitalizador"
    }
    if (vuexState.auth.roles.includes("Expediente_Auditor")) {
      this.addTitle = "Auditor"
    }
    if (vuexState.auth.roles.includes("Expediente_Radicador")) {
      this.addTitle = "Radicador"
    }
    if (vuexState.auth.roles.includes("Expediente_Caja")) {
      this.addTitle = "Caja";
    }

  },
  computed: {
    ...mapState(["auth", "notify", "busy", "enterprise"]),
  },
  methods: {
    ...mapMutations([
      "showSuccess",
      "showError"
    ]),
    guardarSeleccion() {
      localStorage.setItem("scannerSeleccionado", this.selectedScanner);
    },
    cargarSeleccion() {
      const scannerGuardado = localStorage.getItem("scannerSeleccionado");
      if (scannerGuardado) {
        this.selectedScanner = scannerGuardado;
      }
      console.log("selectedScanner = " + this.selectedScanner)
    },
    guardarFormula() {
      if (!this.pdfBase64) {
        console.error("No se encontró el PDF en base64");
        this.snackbarMessage = "⚠️ No se encontró el archivo escaneado. Por favor, escanee primero.";
        this.snackbarColor = "warning";
        this.snackbar = true;
        this.snackbarTimeout = 14000;
        return;
      }

      // Limpiar encabezado si viene con 'data:application/pdf;base64,'
      const base64Clean = this.pdfBase64.replace(/^data:application\/pdf;base64,/, '');
      this.showPdfDialog = false;

      this.snackbarMessage = "📡 Escaneando y guardando fórmula, continúa escaneando...";
      this.snackbarColor = "info";
      this.snackbar = true;
      this.snackbarTimeout = 14000;

      const payload = {
        pdfBase64: base64Clean
      };


      this.$http.post("msa-process-file/api/file/cargarFormula", payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(({ data }) => {
          console.log("respuesta cargarFormula", data);
          if (data.uploadResponse === "ok") {
            this.snackbarMessage = "✅ La fórmula ha sido  guardada correctamente. El expediente se está generando en segundo plano.";
            this.snackbarColor = "success";
          } else {
            this.snackbarMessage = "⚠️ No se pudo cargar el archivo. Por favor, inténtelo nuevamente.";
            this.snackbarColor = "warning";
          }
          this.snackbar = true;
          this.snackbarTimeout = 14000;
        })
        .catch(err => {
          console.error(err);
          this.snackbarMessage = "❌ Error al guardar la fórmula. Verifique la conexión o intente nuevamente.";
          this.snackbarColor = "error";
          this.snackbar = true;
          this.snackbarTimeout = 14000;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getNombrePaciente(dataJsonStr) {
      try {
        const json = JSON.parse(dataJsonStr);
        return json.datos_paciente?.nombre_paciente || 'Sin nombre';
      } catch (e) {
        return 'Sin nombre';
      }
    },
    selectPdfFile() {
      this.$refs.pdfInput.click();
    },
    onFileChange(event) {
      const file = event.target.files[0];
      if (file && file.type === "application/pdf") {
        this.readFileAsBase64(file);
      }
    },
    handleDrop(event) {
      const file = event.dataTransfer.files[0];
      if (file && file.type === "application/pdf") {
        this.readFileAsBase64(file);
      }
    },
    readFileAsBase64(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.pdfBase64 = e.target.result;
        this.showPdfDialog = true;
        console.log(this.pdfBase64)
      };
      reader.readAsDataURL(file);
    },

    scanSimple() {
      console.log("Escaneo simple iniciado");
    },
    scanMultiple() {
      console.log("Escaneo múltiple iniciado");
    },
    openCustomScanModal() {
      this.showCustomScanModal = true;
    },
    startCustomScan() {
      console.log("Iniciar escaneo personalizado con:", this.customScanSettings);
      this.showCustomScanModal = false;
      // Aquí llamas a la función que dispare el escaneo con esas opciones
    },
    formatTime(dateString) {
      if (!dateString) return "";
      const date = new Date(dateString);
      return date.toLocaleTimeString("es-ES", { hour: "2-digit", minute: "2-digit", hour12: true });
    },
    getExpedienteCreado() {
      const now = new Date();
      const formattedDate = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')} 00:00:00`;

      this.$http.get(`/msa-process-file/api/expediente/listarExpedienteBandeja`, {
        params: {
          idEmpresa: this.idEmpresa,
          codigoBodega: this.bodegaId,
          fecha: formattedDate
        }
      })
        .then(({ data }) => {
          this.expedientes = data;
        })
        .catch(error => {
          console.error("Error en la petición:", error);
        });
    },
    getBodegas() {
      this.$http.get(`msa-process-file/api/expediente/listarBodegasExpediente`)
        .then(({ data }) => {
          this.bodegas = data;
        })
        .catch(err => console.log(err))
    },
    getScanner() {
      this.$http.get("msa-process-file/api/file/getScanner")
        .then(({ data }) => {
          this.listaScanner = data;
          // Restaurar selección previa
          const savedScanner = localStorage.getItem("selectedScanner");
          if (savedScanner && data.some(scanner => scanner.hostname === savedScanner)) {
            this.selectedScanner = savedScanner;
          }
        })
        .catch(err => console.log(err));
    },
    getBodega() {
      this.$http.get(`msa-process-file/api/file/getBodegaUsuario`)
        .then(({ data }) => {
          this.bodegaId = data.codigoBodega;
          this.nombreBodega = data.nombreBodega;
          this.idEmpresa = data.idEmpresa;
          this.getExpedienteCreado();
          this.connectWebSocket();
        })
        .catch(err => console.log(err))
    },
    scan() {
      this.snackbarMessage = "Escaneando y guardando formula, continua escanenando ";
      this.snackbarColor = "info";
      this.snackbar = true;
      this.snackbarTimeout = 14000;
      console.log(this.selectedScanner)
      this.$http.get("msa-process-file/api/file/scan?hostname="+ this.selectedScanner)
        .then(({ data }) => {
          console.log("scan", data);
          if (data.uploadResponse === "ok") {
            this.snackbarMessage = "✅ La fórmula ha sido escaneada correctamente. El expediente se está generando en segundo plano..";
            this.snackbarColor = "info";
          } else {
            this.snackbarMessage = "⚠️ No se pudo cargar el archivo. Por favor, inténtelo nuevamente.";
            this.snackbarColor = "error";
          }
          this.snackbar = true;
          this.snackbarTimeout = 14000;
        })
        .catch(err => {
          console.log(err);
          this.snackbarMessage = "❌ Error al escanear la fórmula. Verifique la conexión del escáner e intente nuevamente.";
          this.snackbarColor = "error";
          this.snackbar = true;
          this.snackbarTimeout = 14000;
          this.loading = false;
        });
    },
    getFormulas() {
      this.loading = true;
      this.$http.get(`/msa-process-file/api/file/listarBandeja`)
        .then(({ data }) => {
          console.log(data);
          this.loading = false;
          this.formulas = data;
        })
        .catch(err => console.log(err));
    },
    connectWebSocket() {
      const socket = new SockJS(wsUrl);
      this.stompClient = Stomp.over(socket);

      // Opcional: silenciar logs de debug
      this.stompClient.debug = null;

      // Conectar WebSocket
      this.stompClient.connect(
        {}, // Sin headers
        () => {
          console.log('✅ Conectado al WebSocket');
          this.subscribeToTopics(); // Llamamos a la función que maneja las suscripciones
        },
        (error) => {
          console.error('❌ Error al conectar:', error);
        }
      );
    },

    subscribeToTopics() {
      if (!this.stompClient || !this.stompClient.connected) {
        console.error('⚠️ WebSocket no está conectado aún.');
        return;
      }

      // 📝 Suscripción a "/topic/pdfSeleccionado"
      this.stompClient.subscribe('/topic/pdfSeleccionado', (message) => {
        const selectedPdfUrl = message.body;
        console.log("📄 PDF recibido:", selectedPdfUrl);
        this.formulas = this.formulas.filter(pdf => pdf.nombreArchivo !== selectedPdfUrl);
      });

      // 📑 Suscripción a "/topic/formulaEscaneada"
      let topicFormulaEscaneada = '/topic/formulaEscaneada' + this.bodegaId;
      this.stompClient.subscribe(topicFormulaEscaneada, (message) => {
        try {
          const formulaEscaneada = JSON.parse(message.body);
          console.log("📑 Formula escaneada recibida:", formulaEscaneada);

          const index = this.formulas.findIndex(f => f.nombreArchivo === formulaEscaneada.nombreArchivo);

          if (index !== -1) {
            // Si ya existe y su estado cambia de PROCESSING a MANUAL, actualizarlo usando $set
            if (this.formulas[index].estadoFormulaEscaneada === "PROCESSING" && formulaEscaneada.estadoFormulaEscaneada === "MANUAL") {
              this.$set(this.formulas, index, formulaEscaneada); // Forzar la reactividad
            }
          } else {
            // Si el estado es PROCESSING, agregarlo a la lista
            if (formulaEscaneada.estadoFormulaEscaneada === "PROCESSING") {
              this.formulas.push(formulaEscaneada);
            }
          }

          console.log("📜 Lista actualizada:", this.formulas);
        } catch (error) {
          console.error("❌ Error al parsear el mensaje WebSocket:", error);
        }
      });

      // 📁 Suscripción a "/topic/expedienteCreado"
      this.stompClient.subscribe('/topic/expedienteCreado', (message) => {
        const nuevoExpediente = JSON.parse(message.body);
        console.log("📁 Nuevo expediente recibido:", nuevoExpediente);
        this.expedientes.unshift(nuevoExpediente);
      });

      console.log("📡 Suscrito a los tópicos correctamente.");
    },
    seleccionarPDF(formula) {
      // Informa al backend que este PDF fue seleccionado
      let pdf = formula.nombreArchivo;
      console.log(pdf)
      this.$http.put("msa-process-file/api/expediente/seleccionarpdf/" + pdf)
        .then(({ data }) => {
          console.log(data)
          // Elimina localmente
          this.formulas = this.formulas.filter(f => f.nombreArchivo !== formula.nombreArchivo);
        })
        .catch(err => console.error("Error al seleccionar PDF:", err));
    },
    async empresasUsuario() {
      const codEmpresas = await this.$http.get(`msa-setting/api/usem/relacion/${this.usuario}`)
        .then(({ data }) => this.codigosEmpresas = data)
        .catch(err => console.log(err))

      if (codEmpresas != []) {
        await this.$http.post('msa-setting/api/empresa/list', this.codigosEmpresas)
          .then(({ data }) => this.dataEmpresas = data)
          .catch(err => console.log(err))
      }
    },
    toEnterprise(enterprise) {
      this.auth.roles = this.rolesKeycloak;
      this.setEnterprise(enterprise)
    },
    redirectToDigitalizador(archivo) {
      console.log(archivo);
      this.seleccionarPDF(archivo)

      let name = archivo.idFormulaEscaneada;
      this.$router.push({
        name: 'modules.expedienteMain.digitalizador',
        query: { name: name }
      });

    },
    beforeDestroy() {
      this.stopPolling();
      clearInterval(this.intervalId);
    },

  }
}
</script>

<style scoped>
.drop-zone {
  border: 2px dashed #b0bec5;
  border-radius: 12px;
  padding: 20px;
  text-align: center;
  background-color: #fafafa;
  transition: border-color 0.3s, background-color 0.3s;
}

.drop-zone:hover {
  border-color: #1976d2;
  background-color: #f0f8ff;
}

.scan-btn {
  font-size: 17px;
  padding: 20px 30px;
  border-radius: 10px;
}


.disabled-icon {
  pointer-events: none;
  opacity: 0.5;
}

.tables-container {
  display: flex;
  gap: 20px;
}

.table-card-expedientes {
  width: 55%;
}

.table-card-formulas {
  width: 45%;
}

.v-data-table {
  overflow-y: auto;
}

ul,
ol {
  list-style: none;
}

article.formulas {
  width: 100%;
}

article.formulas>section {
  display: -webkit-box;
  display: -ms-flexbox;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
}

article.formulas>section>section>h4 {
  text-align: center;
  margin-top: 40px;
  font-size: 18px;
  font-weight: 400;
}

article.formulas>section>header {
  padding: 1rem 0;
  background: white;
  -webkit-box-shadow: 2px 2px 5px -1px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 2px 2px 5px -1px rgba(0, 0, 0, 0.23);
  box-shadow: 2px 2px 5px -1px rgba(0, 0, 0, 0.23);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%
}

article.formulas>section>header h3 {
  padding-left: 1rem;
  margin-bottom: 1rem;
  text-align: left;
  color: #0D47A1;
  width: 90%;
}

article.formulas>section>header .calendar {
  position: relative;
}

article.formulas>section>header .calendar figure {
  position: absolute;
  right: 10px;
  top: 15px;
}

article.formulas>section>header>article {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 90%;
  margin-top: 10px;
}

article.formulas>section>header>article div {
  margin: 0 1rem;
  width: 100%;
}

article.formulas>section>header>article div:first-child>div {
  margin-top: -22px;
}

article.formulas>section>header>article div label {
  margin-left: .5rem;
  width: 100%;
}

article.formulas>section>section {
  width: 100%;
}

article.formulas>section>section>ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
}

article.formulas>section>section>ul li {
  aspect-ratio: 1 / 1;
  background: white;
  border-radius: 1rem;
  position: relative;
  cursor: pointer;
  padding: 1%;
  margin: 2%;
  width: 15%;
  min-width: 100px;
  overflow: hidden;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
}

article.formulas>section>section>ul li>header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  height: 100%;
}

article.formulas>section>section>ul li>header h4 {
  text-align: center;
  width: 100%;
}

article.formulas>section>section>ul li>header h5 {
  text-align: center;
  white-space: nowrap;
  width: 100%;
  font-size: 15px;
}

article.formulas>section>section>ul li>header figure {
  margin-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
}

.pdf {
  font-size: 30px;
  color: #FF5733;
  ;
}

article.formulas>section>section>ul li>.check {
  margin-top: 1rem;
  background-color: white;
  display: none;
  position: absolute;
  bottom: -10px;
  right: -10px;
}

article.formulas>section>section>ul li>.check i {
  font-size: 1.2rem;
  color: green;
  padding: .2rem;
  margin-right: 0 !important;
}

article.formulas>section>section>ul li.completa>.check {
  margin-top: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid green;
  border-radius: 50%;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(100vh - 53px);
  background-color: rgba(0, 0, 0, .5);
  z-index: 2;
}

.loading h4 {
  width: 100%;
  text-align: center;
  color: white;
  font-size: 19px;
  margin-top: -20px;
  font-weight: 200;
}

@keyframes ldio-yzaezf3dcmj {
  0% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}

.ldio-yzaezf3dcmj div {
  left: 96px;
  top: 45px;
  position: absolute;
  animation: ldio-yzaezf3dcmj linear 1s infinite;
  background: #0d47a1;
  width: 8px;
  height: 18px;
  border-radius: 3.42px / 3.42px;
  transform-origin: 4px 55px;
}

.ldio-yzaezf3dcmj div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.9375s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(2) {
  transform: rotate(22.5deg);
  animation-delay: -0.875s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(3) {
  transform: rotate(45deg);
  animation-delay: -0.8125s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(4) {
  transform: rotate(67.5deg);
  animation-delay: -0.75s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(5) {
  transform: rotate(90deg);
  animation-delay: -0.6875s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(6) {
  transform: rotate(112.5deg);
  animation-delay: -0.625s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(7) {
  transform: rotate(135deg);
  animation-delay: -0.5625s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(8) {
  transform: rotate(157.5deg);
  animation-delay: -0.5s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(9) {
  transform: rotate(180deg);
  animation-delay: -0.4375s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(10) {
  transform: rotate(202.5deg);
  animation-delay: -0.375s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(11) {
  transform: rotate(225deg);
  animation-delay: -0.3125s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(12) {
  transform: rotate(247.5deg);
  animation-delay: -0.25s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(13) {
  transform: rotate(270deg);
  animation-delay: -0.1875s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(14) {
  transform: rotate(292.5deg);
  animation-delay: -0.125s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(15) {
  transform: rotate(315deg);
  animation-delay: -0.0625s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(16) {
  transform: rotate(337.5deg);
  animation-delay: 0s;
  background: #0d47a1;
}

.loadingio-spinner-spinner-2by998twmg8 {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}

.ldio-yzaezf3dcmj {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
  /* see note above */
}

.ldio-yzaezf3dcmj div {
  box-sizing: content-box;
}

.btn-scan {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background 0.3s;
  top: 3px;
  right: 10px;
}

.btn-scan:hover {
  background-color: #0056b3;
}

h6 {
  font-size: 1.0em;
}

label {
  cursor: pointer;
}
</style>